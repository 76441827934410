import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseHttpResource } from '../base-http.resource';
import { CoreListEnvelop } from '../list-envelop/core-list-envelop.model';
import { CreateWalletDto, WalletDto } from './wallet.dto';
import { generateWorkspacesUpdateBody } from './wallet.helper';

export interface AddWorkspacesInput {
  wallet: WalletDto;
  currentAccount: string;
  selectedAccounts: string[];
}

@Injectable({ providedIn: 'root' })
export class WalletResource extends BaseHttpResource<
  WalletDto,
  CoreListEnvelop<WalletDto>,
  CreateWalletDto
> {
  constructor() {
    super('wallet');
  }

  updateWorkspaces({
    wallet,
    currentAccount,
    selectedAccounts,
  }: AddWorkspacesInput): Observable<unknown> {
    return super.update(
      wallet.id,
      generateWorkspacesUpdateBody({ wallet, currentAccount, selectedAccounts })
    );
  }
}
