import { CreateWalletDto } from '@mkp/shared/data-access';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const creditManagementPageActions = createActionGroup({
  source: 'Credit Management Page',
  events: {
    enter: emptyProps(),
    addNewWallet: props<Pick<CreateWalletDto, 'name'>>(),
    loadCredits: emptyProps(),
  },
});
