import * as fromCompany from '@company/store/effects';
import { LanguageEffects } from './language.effects';
import { RouterEffects } from './router.effects';
import { SeoEffects } from './seo.effects';
import { SettingsEffects } from './settings.effects';

export const effects = [
  RouterEffects,
  SettingsEffects,
  ...fromCompany.effects,
  SeoEffects,
  LanguageEffects,
];
