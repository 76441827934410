import { inject, Injectable } from '@angular/core';
import {
  AccountMembershipRole,
  AccountMembershipState,
  CreateAccountMembershipDto,
} from '@mkp/account-membership/models';
import { BaseHttpResource, CoreListEnvelop } from '@mkp/shared/data-access';
import { QueryOptions } from '@shared/models';
import { map, Observable, filter as rxFilter } from 'rxjs';
import { AccountMembershipDto } from './account-membership.dto';
import { AccountMembershipMapper } from './account-membership.mapper';
import { AccountMembership, AccountMembershipPatch } from './account-membership.model';

interface PaginatedAccountMemberships {
  accountMemberships: AccountMembership[];
  _links: any;
  filter: string;
  totalCount: number;
}

interface CreateAccountMembershipPayload {
  accountId: string;
  userId: string;
}

@Injectable({ providedIn: 'root' })
export class AccountMembershipResource extends BaseHttpResource<
  AccountMembershipDto,
  CoreListEnvelop<AccountMembershipDto>
> {
  private mapper = inject(AccountMembershipMapper);
  constructor() {
    super('account-membership');
  }

  /**
   * Creates a new account membership with admin role
   * and active state.
   */
  createAccountMembership({
    accountId,
    userId,
  }: CreateAccountMembershipPayload): Observable<AccountMembership> {
    const createAccountMembershipDto: CreateAccountMembershipDto = {
      accountId,
      userId,
      roles: [{ role: AccountMembershipRole.Admin }],
      state: AccountMembershipState.Active,
    };

    return this.http
      .post<AccountMembershipDto>(this.url, createAccountMembershipDto)
      .pipe(map((dto) => this.mapper.fromJson(dto)));
  }

  getAccountMembershipWithUserIdAndAccountId({
    userId,
    accountId,
  }: {
    userId: string;
    accountId: string;
  }): Observable<AccountMembership | undefined> {
    const options = { filter: `user.id==${userId};account.id==${accountId}`, limit: 1 };

    return this.getWithQuery(options).pipe(
      map(({ _embedded }) => _embedded),
      map(({ results: membershipDto }) =>
        membershipDto[0] ? this.mapper.fromJson(membershipDto[0]) : undefined
      )
    );
  }

  /**
   * Returns a list of account memberships and maps it to AccountMembership model.
   */
  public listAccountMemberships(query: QueryOptions): Observable<AccountMembership[]> {
    const options = query || { limit: 25 };
    return this.getWithQuery(options).pipe(
      map(({ _embedded }) => _embedded),
      rxFilter(Boolean),
      map(({ results: membershipDto }) => membershipDto.map((dto) => this.mapper.fromJson(dto)))
    );
  }

  /**
   * Returns a list of account memberships and maps it to AccountMembership model
   * with pagination details included.
   */
  public listPaginatedAccountMemberships(
    query: QueryOptions
  ): Observable<PaginatedAccountMemberships> {
    const options = query || { limit: 25 };
    return this.getWithQuery(options).pipe(
      map(({ _embedded, filter, _links, totalCount }) => ({
        accountMemberships: _embedded.results.map((dto) => this.mapper.fromJson(dto)),
        _links,
        filter,
        totalCount,
      }))
    );
  }

  public patchAccountMembershipState(
    accountMembershipPatch: AccountMembershipPatch
  ): Observable<AccountMembership> {
    return this.update(
      accountMembershipPatch.id,
      this.mapper.toJson(accountMembershipPatch),
      'patch'
    ).pipe(map((accountMembershipDto) => this.mapper.fromJson(accountMembershipDto)));
  }
}
