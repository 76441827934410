import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { AppConfig } from '@config/app.config';
import { selectActiveAccounts } from '@mkp/account/state';
import { selectWorkspaceFromRouteGuardActions } from '@mkp/auth/actions';
import { selectWorkspaceSelecting } from '@mkp/auth/state';
import { LoadingState } from '@mkp/shared/util-state';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { filter, map, Observable, take, tap } from 'rxjs';

export const selectWorkspaceFromRouteGuard: (
  route: ActivatedRouteSnapshot
) => Observable<true | UrlTree> = (route) => {
  const store = inject(Store);
  const router = inject(Router);

  const workspaceId = route.paramMap.get('workspaceId');
  const isWorkspaceLoaded$ = store
    .select(selectWorkspaceSelecting)
    .pipe(filter((state) => state === LoadingState.LOADED));

  return isWorkspaceLoaded$.pipe(
    take(1),
    // check if the account exists in the store
    concatLatestFrom(() => [store.select(selectActiveAccounts)]),
    map(([, accounts]) => accounts.find(({ id }) => id === workspaceId)?.id),
    tap((accountId) =>
      store.dispatch(selectWorkspaceFromRouteGuardActions.selectAccountIdFromRoute({ accountId }))
    ),
    // if account-id is not found in the route or doesn't correspond to an active account: redirect to select-workspace page
    map((accountId) => (accountId ? true : getSelectWorkspaceUrlTree(router)))
  );
};

const getSelectWorkspaceUrlTree = (router: Router): UrlTree =>
  router.createUrlTree([AppConfig.routes.selectAccountMembership]);
