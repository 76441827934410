import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { appInitFactoryActions, languageEffectsActions } from '@store/actions';
import { State } from '@store/reducers';
import { Observable, switchMap, take, tap } from 'rxjs';
import { RedirectService } from './services/redirect.service';

export const appInitFactory = (
  store: Store<State>,
  actions$: Actions,
  authService: AuthService,
  router: Router,
  redirectService: RedirectService
): (() => Observable<unknown>) => {
  router.resetConfig([
    {
      path: '',
      loadChildren: () => authenticationCheck(authService),
    },
  ]);

  return () => {
    store.dispatch(appInitFactoryActions.enter());

    return actions$.pipe(
      ofType(
        languageEffectsActions.appLanguageInitializedForUser,
        languageEffectsActions.appLanguageInitializedForVisitor
      ),
      switchMap(() => authService.isAuthenticated$),
      tap((isAuthenticated) => {
        
        if (!isAuthenticated) {
          redirectService.handleRedirect();
        }
      }),
      take(1)
    );
  };
};

const authenticationCheck = (authService: AuthService) => {
  return authService.isAuthenticated$.pipe(
    switchMap((isAuthenticated) => (isAuthenticated ? authenticatedRoutes : visitorRoutes)),
    take(1)
  );
};

const authenticatedRoutes = import('@mkp/shared/routes').then((m) => m.authenticatedRoutes);
const visitorRoutes = import('@mkp/shared/routes').then((m) => m.visitorRoutes);
